import cloveWasm from './clove-wasm'

let encoder

function arrayToWasm (array) {
  const nBytes = array.length * array.BYTES_PER_ELEMENT
  const offset = 2 * array.BYTES_PER_ELEMENT
  let ptr = encoder._malloc(nBytes + (offset * 2))
  let buf = new Uint8Array(encoder.HEAPU8.buffer, ptr + offset, nBytes)
  buf.set(new Uint8Array(array.buffer))
  new Uint8Array(encoder.HEAPU8.buffer, ptr, offset).fill(0)
  new Uint8Array(encoder.HEAPU8.buffer, ptr + offset + nBytes, offset).fill(0)
  return ptr
}

function loadWasm() {
  cloveWasm().then(wasm => {
    encoder = wasm
  })
}

class Clove {
  constructor (sampleRate) {
    this.encoder = encoder._enc_new(sampleRate)
    if (!this.encoder) throw('unable to create Clove encoder')
  }
  process (floatArray) {
    if (floatArray.length) {
      let input = arrayToWasm(floatArray)
      const nSamples = floatArray.length
      let packets = []
      let cb = (base, len) => {
        let str = encoder.UTF8ArrayToString(encoder.HEAPU8, base, len)
        packets.push(str)
      }
      let funcRef = encoder.addFunction(cb, 'vii')
      encoder._enc_process(this.encoder, input, nSamples, funcRef)
      encoder.removeFunction(funcRef)
      encoder._free(input)
      return packets
    }
  }
}

export { Clove, loadWasm }