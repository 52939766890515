
var clove = (function() {
  var _scriptDir = import.meta.url;
  
  return (
function(clove) {
  clove = clove || {};


var a;
a || (a = typeof clove !== 'undefined' ? clove : {});
var b = {}, e;
for (e in a) {
  a.hasOwnProperty(e) && (b[e] = a[e]);
}
var k = !1, n = !1;
k = "object" === typeof window;
n = "function" === typeof importScripts;
if (a.ENVIRONMENT) {
  throw Error("Module.ENVIRONMENT has been deprecated. To force the environment, use the ENVIRONMENT compile-time option (for example, -s ENVIRONMENT=web or -s ENVIRONMENT=node)");
}
var p = "", q;
if (k || n) {
  n ? p = self.location.href : document.currentScript && (p = document.currentScript.src);
  _scriptDir && (p = _scriptDir);
  0 !== p.indexOf("blob:") ? p = p.substr(0, p.lastIndexOf("/") + 1) : p = "";
  if ("object" !== typeof window && "function" !== typeof importScripts) {
    throw Error("not compiled for this environment (did you build to HTML and try to run it not on the web, or set ENVIRONMENT to something - like node - and run it someplace else - like on the web?)");
  }
  n && (q = function(c) {
    var d = new XMLHttpRequest;
    d.open("GET", c, !1);
    d.responseType = "arraybuffer";
    d.send(null);
    return new Uint8Array(d.response);
  });
} else {
  throw Error("environment detection error");
}
var aa = a.print || console.log.bind(console), r = a.printErr || console.warn.bind(console);
for (e in b) {
  b.hasOwnProperty(e) && (a[e] = b[e]);
}
b = null;
Object.getOwnPropertyDescriptor(a, "arguments") || Object.defineProperty(a, "arguments", {configurable:!0, get:function() {
  t("Module.arguments has been replaced with plain arguments_");
}});
Object.getOwnPropertyDescriptor(a, "thisProgram") || Object.defineProperty(a, "thisProgram", {configurable:!0, get:function() {
  t("Module.thisProgram has been replaced with plain thisProgram");
}});
Object.getOwnPropertyDescriptor(a, "quit") || Object.defineProperty(a, "quit", {configurable:!0, get:function() {
  t("Module.quit has been replaced with plain quit_");
}});
u("undefined" === typeof a.memoryInitializerPrefixURL, "Module.memoryInitializerPrefixURL option was removed, use Module.locateFile instead");
u("undefined" === typeof a.pthreadMainPrefixURL, "Module.pthreadMainPrefixURL option was removed, use Module.locateFile instead");
u("undefined" === typeof a.cdInitializerPrefixURL, "Module.cdInitializerPrefixURL option was removed, use Module.locateFile instead");
u("undefined" === typeof a.filePackagePrefixURL, "Module.filePackagePrefixURL option was removed, use Module.locateFile instead");
u("undefined" === typeof a.read, "Module.read option was removed (modify read_ in JS)");
u("undefined" === typeof a.readAsync, "Module.readAsync option was removed (modify readAsync in JS)");
u("undefined" === typeof a.readBinary, "Module.readBinary option was removed (modify readBinary in JS)");
u("undefined" === typeof a.setWindowTitle, "Module.setWindowTitle option was removed (modify setWindowTitle in JS)");
u("undefined" === typeof a.TOTAL_MEMORY, "Module.TOTAL_MEMORY has been renamed Module.INITIAL_MEMORY");
Object.getOwnPropertyDescriptor(a, "read") || Object.defineProperty(a, "read", {configurable:!0, get:function() {
  t("Module.read has been replaced with plain read_");
}});
Object.getOwnPropertyDescriptor(a, "readAsync") || Object.defineProperty(a, "readAsync", {configurable:!0, get:function() {
  t("Module.readAsync has been replaced with plain readAsync");
}});
Object.getOwnPropertyDescriptor(a, "readBinary") || Object.defineProperty(a, "readBinary", {configurable:!0, get:function() {
  t("Module.readBinary has been replaced with plain readBinary");
}});
Object.getOwnPropertyDescriptor(a, "setWindowTitle") || Object.defineProperty(a, "setWindowTitle", {configurable:!0, get:function() {
  t("Module.setWindowTitle has been replaced with plain setWindowTitle");
}});
var v, w;
v = w = function() {
  t("cannot use the stack before compiled code is ready to run, and has provided stack access");
};
function ba(c) {
  x || (x = {});
  x[c] || (x[c] = 1, r(c));
}
var x, ca = {"f64-rem":function(c, d) {
  return c % d;
}, "debugger":function() {
  debugger;
}}, y = Array(5), da = 0, z;
a.wasmBinary && (z = a.wasmBinary);
Object.getOwnPropertyDescriptor(a, "wasmBinary") || Object.defineProperty(a, "wasmBinary", {configurable:!0, get:function() {
  t("Module.wasmBinary has been replaced with plain wasmBinary");
}});
var noExitRuntime;
a.noExitRuntime && (noExitRuntime = a.noExitRuntime);
Object.getOwnPropertyDescriptor(a, "noExitRuntime") || Object.defineProperty(a, "noExitRuntime", {configurable:!0, get:function() {
  t("Module.noExitRuntime has been replaced with plain noExitRuntime");
}});
"object" !== typeof WebAssembly && t("No WebAssembly support found. Build with -s WASM=0 to target JavaScript instead.");
var A, ea = new WebAssembly.Table({initial:56, maximum:56, element:"anyfunc"}), B = !1;
function u(c, d) {
  c || t("Assertion failed: " + d);
}
var fa = "undefined" !== typeof TextDecoder ? new TextDecoder("utf8") : void 0;
function C(c, d, f) {
  var g = d + f;
  for (f = d; c[f] && !(f >= g);) {
    ++f;
  }
  if (16 < f - d && c.subarray && fa) {
    return fa.decode(c.subarray(d, f));
  }
  for (g = ""; d < f;) {
    var h = c[d++];
    if (h & 128) {
      var l = c[d++] & 63;
      if (192 == (h & 224)) {
        g += String.fromCharCode((h & 31) << 6 | l);
      } else {
        var m = c[d++] & 63;
        224 == (h & 240) ? h = (h & 15) << 12 | l << 6 | m : (240 != (h & 248) && ba("Invalid UTF-8 leading byte 0x" + h.toString(16) + " encountered when deserializing a UTF-8 string on the asm.js/wasm heap to a JS string!"), h = (h & 7) << 18 | l << 12 | m << 6 | c[d++] & 63);
        65536 > h ? g += String.fromCharCode(h) : (h -= 65536, g += String.fromCharCode(55296 | h >> 10, 56320 | h & 1023));
      }
    } else {
      g += String.fromCharCode(h);
    }
  }
  return g;
}
function D(c) {
  return c ? C(E, c, void 0) : "";
}
"undefined" !== typeof TextDecoder && new TextDecoder("utf-16le");
var F, ha, E, G, H;
u(!0, "stack must start aligned");
u(!0, "heap must start aligned");
a.TOTAL_STACK && u(5242880 === a.TOTAL_STACK, "the stack size can no longer be determined at runtime");
var I = a.INITIAL_MEMORY || 16777216;
Object.getOwnPropertyDescriptor(a, "INITIAL_MEMORY") || Object.defineProperty(a, "INITIAL_MEMORY", {configurable:!0, get:function() {
  t("Module.INITIAL_MEMORY has been replaced with plain INITIAL_INITIAL_MEMORY");
}});
u(5242880 <= I, "INITIAL_MEMORY should be larger than TOTAL_STACK, was " + I + "! (TOTAL_STACK=5242880)");
u("undefined" !== typeof Int32Array && "undefined" !== typeof Float64Array && void 0 !== Int32Array.prototype.subarray && void 0 !== Int32Array.prototype.set, "JS engine does not provide full typed array support");
a.wasmMemory ? A = a.wasmMemory : A = new WebAssembly.Memory({initial:I / 65536, maximum:I / 65536});
A && (F = A.buffer);
I = F.byteLength;
u(0 === I % 65536);
var J = F;
F = J;
a.HEAP8 = ha = new Int8Array(J);
a.HEAP16 = new Int16Array(J);
a.HEAP32 = G = new Int32Array(J);
a.HEAPU8 = E = new Uint8Array(J);
a.HEAPU16 = new Uint16Array(J);
a.HEAPU32 = H = new Uint32Array(J);
a.HEAPF32 = new Float32Array(J);
a.HEAPF64 = new Float64Array(J);
G[11476] = 5288976;
function ia() {
  u(!0);
  H[1322243] = 34821223;
  H[1322242] = 2310721022;
  G[0] = 1668509029;
}
function K() {
  var c = H[1322243], d = H[1322242];
  34821223 == c && 2310721022 == d || t("Stack overflow! Stack cookie has been overwritten, expected hex dwords 0x89BACDFE and 0x2135467, but received 0x" + d.toString(16) + " " + c.toString(16));
  1668509029 !== G[0] && t("Runtime error: The application has corrupted its heap memory area (address zero)!");
}
function ja(c) {
  t("Stack overflow! Attempted to allocate " + c + " bytes on the stack, but stack has only " + (5288976 - v() + c) + " bytes available!");
}
var ka = new Int16Array(1), la = new Int8Array(ka.buffer);
ka[0] = 25459;
if (115 !== la[0] || 99 !== la[1]) {
  throw "Runtime error: expected the system to be little-endian!";
}
function L(c, d) {
  t("Invalid function pointer " + c + " called with signature '" + d + "'. Perhaps this is an invalid value (e.g. caused by calling a virtual method on a NULL pointer)? Or calling a function with an incorrect type, which will fail? (it is worth building your source files with -Werror (warnings are errors), as warnings can indicate undefined behavior which can cause this). Build with ASSERTIONS=2 for more info.");
}
function M(c) {
  for (; 0 < c.length;) {
    var d = c.shift();
    if ("function" == typeof d) {
      d(a);
    } else {
      var f = d.c;
      "number" === typeof f ? void 0 === d.b ? a.dynCall_v(f) : a.dynCall_vi(f, d.b) : f(void 0 === d.b ? null : d.b);
    }
  }
}
var ma = [], na = [], oa = [], pa = [], N = !1;
function qa() {
  var c = a.preRun.shift();
  ma.unshift(c);
}
u(Math.imul, "This browser does not support Math.imul(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill");
u(Math.fround, "This browser does not support Math.fround(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill");
u(Math.clz32, "This browser does not support Math.clz32(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill");
u(Math.trunc, "This browser does not support Math.trunc(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill");
var O = 0, R = null, S = null, T = {};
function ra() {
  O++;
  a.monitorRunDependencies && a.monitorRunDependencies(O);
  u(!T["wasm-instantiate"]);
  T["wasm-instantiate"] = 1;
  null === R && "undefined" !== typeof setInterval && (R = setInterval(function() {
    if (B) {
      clearInterval(R), R = null;
    } else {
      var c = !1, d;
      for (d in T) {
        c || (c = !0, r("still waiting on run dependencies:")), r("dependency: " + d);
      }
      c && r("(end of list)");
    }
  }, 10000));
}
a.preloadedImages = {};
a.preloadedAudios = {};
function t(c) {
  if (a.onAbort) {
    a.onAbort(c);
  }
  aa(c);
  r(c);
  B = !0;
  c = "abort(" + c + ") at ";
  a: {
    var d = Error();
    if (!d.stack) {
      try {
        throw Error();
      } catch (f) {
        d = f;
      }
      if (!d.stack) {
        d = "(no stack trace available)";
        break a;
      }
    }
    d = d.stack.toString();
  }
  a.extraStackTrace && (d += "\n" + a.extraStackTrace());
  d = sa(d);
  throw new WebAssembly.RuntimeError(c + d);
}
function ua() {
  t("Filesystem support (FS) was not included. The problem is that you are using files from JS, but files were not used from C/C++, so filesystem support was not auto-included. You can force-include filesystem support with  -s FORCE_FILESYSTEM=1");
}
a.FS_createDataFile = function() {
  ua();
};
a.FS_createPreloadedFile = function() {
  ua();
};
function va() {
  var c = U;
  return String.prototype.startsWith ? c.startsWith("data:application/octet-stream;base64,") : 0 === c.indexOf("data:application/octet-stream;base64,");
}
var U = "clove.wasm";
if (!va()) {
  var wa = U;
  U = a.locateFile ? a.locateFile(wa, p) : p + wa;
}
function xa() {
  try {
    if (z) {
      return new Uint8Array(z);
    }
    if (q) {
      return q(U);
    }
    throw "both async and sync fetching of the wasm failed";
  } catch (c) {
    t(c);
  }
}
function ya() {
  return z || !k && !n || "function" !== typeof fetch ? new Promise(function(c) {
    c(xa());
  }) : fetch(U, {credentials:"same-origin"}).then(function(c) {
    if (!c.ok) {
      throw "failed to load wasm binary file at '" + U + "'";
    }
    return c.arrayBuffer();
  }).catch(function() {
    return xa();
  });
}
a.asm = function() {
  function c(l) {
    a.asm = l.exports;
    O--;
    a.monitorRunDependencies && a.monitorRunDependencies(O);
    u(T["wasm-instantiate"]);
    delete T["wasm-instantiate"];
    0 == O && (null !== R && (clearInterval(R), R = null), S && (l = S, S = null, l()));
  }
  function d(l) {
    u(a === h, "the Module object should not be replaced during async compilation - perhaps the order of HTML elements is wrong?");
    h = null;
    c(l.instance);
  }
  function f(l) {
    return ya().then(function(m) {
      return WebAssembly.instantiate(m, g);
    }).then(l, function(m) {
      r("failed to asynchronously prepare wasm: " + m);
      t(m);
    });
  }
  var g = {env:V, wasi_snapshot_preview1:V, global:{NaN:NaN, Infinity:Infinity}, "global.Math":Math, asm2wasm:ca};
  ra();
  var h = a;
  if (a.instantiateWasm) {
    try {
      return a.instantiateWasm(g, c);
    } catch (l) {
      return r("Module.instantiateWasm callback failed with error: " + l), !1;
    }
  }
  (function() {
    if (z || "function" !== typeof WebAssembly.instantiateStreaming || va() || "function" !== typeof fetch) {
      return f(d);
    }
    fetch(U, {credentials:"same-origin"}).then(function(l) {
      return WebAssembly.instantiateStreaming(l, g).then(d, function(m) {
        r("wasm streaming compile failed: " + m);
        r("falling back to ArrayBuffer instantiation");
        f(d);
      });
    });
  })();
  return {};
};
na.push({c:function() {
  za();
}});
function sa(c) {
  return c.replace(/\b__Z[\w\d_]+/g, function(d) {
    ba("warning: build with  -s DEMANGLE_SUPPORT=1  to link in libcxxabi demangling");
    return d === d ? d : d + " [" + d + "]";
  });
}
var Aa = [null, [], []];
function Ba(c, d, f, g) {
  for (var h = 0, l = 0; l < f; l++) {
    for (var m = G[d + 8 * l >> 2], P = G[d + (8 * l + 4) >> 2], Y = 0; Y < P; Y++) {
      var ta = c, Z = E[m + Y], Q = Aa[ta];
      u(Q);
      0 === Z || 10 === Z ? ((1 === ta ? aa : r)(C(Q, 0)), Q.length = 0) : Q.push(Z);
    }
    h += P;
  }
  G[g >> 2] = h;
  return 0;
}
function Ca(c) {
  return Math.pow(2, c);
}
function Da(c) {
  return Math.log(c) / Math.LN10;
}
function W() {
  W.a || (W.a = []);
  W.a.push(v());
  return W.a.length - 1;
}
var V = {___assert_fail:function(c, d, f, g) {
  t("Assertion failed: " + D(c) + ", at: " + [d ? D(d) : "unknown filename", f, g ? D(g) : "unknown function"]);
}, ___wasi_fd_write:function(c, d, f, g) {
  return Ba(c, d, f, g);
}, __memory_base:1024, __table_base:0, _emscripten_get_heap_size:function() {
  return E.length;
}, _emscripten_memcpy_big:function(c, d, f) {
  E.copyWithin(c, d, d + f);
}, _emscripten_resize_heap:function(c) {
  t("Cannot enlarge memory arrays to size " + (c >>> 0) + " bytes (OOM). Either (1) compile with  -s INITIAL_MEMORY=X  with X higher than the current value " + ha.length + ", (2) compile with  -s ALLOW_MEMORY_GROWTH=1  which allows increasing the size at runtime, or (3) if you want malloc to return NULL (0) instead of this abort, compile with  -s ABORTING_MALLOC=0 ");
}, _fd_write:Ba, _llvm_exp2_f32:Ca, _llvm_exp2_f64:function(c) {
  return Ca(c);
}, _llvm_log10_f32:Da, _llvm_log10_f64:function(c) {
  return Da(c);
}, _llvm_stackrestore:function(c) {
  var d = W.a[c];
  W.a.splice(c, 1);
  w(d);
}, _llvm_stacksave:W, abort:t, abortStackOverflow:ja, getTempRet0:function() {
  return da;
}, jsCall_ii:function(c, d) {
  return y[c](d);
}, jsCall_iiii:function(c, d, f, g) {
  return y[c](d, f, g);
}, jsCall_jiji:function(c, d, f, g) {
  return y[c](d, f, g);
}, jsCall_vii:function(c, d, f) {
  y[c](d, f);
}, jsCall_viiiiiii:function(c, d, f, g, h, l, m, P) {
  y[c](d, f, g, h, l, m, P);
}, memory:A, nullFunc_ii:function(c) {
  L(c, "ii");
}, nullFunc_iiii:function(c) {
  L(c, "iiii");
}, nullFunc_jiji:function(c) {
  L(c, "jiji");
}, nullFunc_vii:function(c) {
  L(c, "vii");
}, nullFunc_viiiiiii:function(c) {
  L(c, "viiiiiii");
}, setTempRet0:function(c) {
  da = c;
}, table:ea, tempDoublePtr:46080}, Ea = a.asm({}, V, F);
a.asm = Ea;
var za = a.__ZL22initialize_malloc_heapv = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm.__ZL22initialize_malloc_heapv.apply(null, arguments);
};
a.___errno_location = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm.___errno_location.apply(null, arguments);
};
a._emscripten_get_sbrk_ptr = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._emscripten_get_sbrk_ptr.apply(null, arguments);
};
a._enc_free = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._enc_free.apply(null, arguments);
};
a._enc_get_sample_rate = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._enc_get_sample_rate.apply(null, arguments);
};
a._enc_new = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._enc_new.apply(null, arguments);
};
a._enc_process = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._enc_process.apply(null, arguments);
};
a._fflush = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._fflush.apply(null, arguments);
};
a._free = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._free.apply(null, arguments);
};
a._malloc = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._malloc.apply(null, arguments);
};
a._memcpy = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._memcpy.apply(null, arguments);
};
a._memmove = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._memmove.apply(null, arguments);
};
a._memset = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm._memset.apply(null, arguments);
};
a.stackAlloc = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm.stackAlloc.apply(null, arguments);
};
w = a.stackRestore = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm.stackRestore.apply(null, arguments);
};
v = a.stackSave = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm.stackSave.apply(null, arguments);
};
a.dynCall_ii = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm.dynCall_ii.apply(null, arguments);
};
a.dynCall_iiii = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm.dynCall_iiii.apply(null, arguments);
};
a.dynCall_jiji = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm.dynCall_jiji.apply(null, arguments);
};
a.dynCall_vii = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm.dynCall_vii.apply(null, arguments);
};
a.dynCall_viiiiiii = function() {
  u(N, "you need to wait for the runtime to be ready (e.g. wait for main() to be called)");
  u(!0, "the runtime was exited (use NO_EXIT_RUNTIME to keep it alive after main() exits)");
  return a.asm.dynCall_viiiiiii.apply(null, arguments);
};
a.asm = Ea;
Object.getOwnPropertyDescriptor(a, "intArrayFromString") || (a.intArrayFromString = function() {
  t("'intArrayFromString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "intArrayToString") || (a.intArrayToString = function() {
  t("'intArrayToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "ccall") || (a.ccall = function() {
  t("'ccall' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "cwrap") || (a.cwrap = function() {
  t("'cwrap' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "setValue") || (a.setValue = function() {
  t("'setValue' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "getValue") || (a.getValue = function() {
  t("'getValue' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "allocate") || (a.allocate = function() {
  t("'allocate' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "getMemory") || (a.getMemory = function() {
  t("'getMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
a.UTF8ArrayToString = C;
Object.getOwnPropertyDescriptor(a, "UTF8ToString") || (a.UTF8ToString = function() {
  t("'UTF8ToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stringToUTF8Array") || (a.stringToUTF8Array = function() {
  t("'stringToUTF8Array' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stringToUTF8") || (a.stringToUTF8 = function() {
  t("'stringToUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "lengthBytesUTF8") || (a.lengthBytesUTF8 = function() {
  t("'lengthBytesUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stackTrace") || (a.stackTrace = function() {
  t("'stackTrace' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "addOnPreRun") || (a.addOnPreRun = function() {
  t("'addOnPreRun' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "addOnInit") || (a.addOnInit = function() {
  t("'addOnInit' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "addOnPreMain") || (a.addOnPreMain = function() {
  t("'addOnPreMain' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "addOnExit") || (a.addOnExit = function() {
  t("'addOnExit' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "addOnPostRun") || (a.addOnPostRun = function() {
  t("'addOnPostRun' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "writeStringToMemory") || (a.writeStringToMemory = function() {
  t("'writeStringToMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "writeArrayToMemory") || (a.writeArrayToMemory = function() {
  t("'writeArrayToMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "writeAsciiToMemory") || (a.writeAsciiToMemory = function() {
  t("'writeAsciiToMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "addRunDependency") || (a.addRunDependency = function() {
  t("'addRunDependency' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
Object.getOwnPropertyDescriptor(a, "removeRunDependency") || (a.removeRunDependency = function() {
  t("'removeRunDependency' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
Object.getOwnPropertyDescriptor(a, "FS_createFolder") || (a.FS_createFolder = function() {
  t("'FS_createFolder' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
Object.getOwnPropertyDescriptor(a, "FS_createPath") || (a.FS_createPath = function() {
  t("'FS_createPath' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
Object.getOwnPropertyDescriptor(a, "FS_createDataFile") || (a.FS_createDataFile = function() {
  t("'FS_createDataFile' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
Object.getOwnPropertyDescriptor(a, "FS_createPreloadedFile") || (a.FS_createPreloadedFile = function() {
  t("'FS_createPreloadedFile' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
Object.getOwnPropertyDescriptor(a, "FS_createLazyFile") || (a.FS_createLazyFile = function() {
  t("'FS_createLazyFile' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
Object.getOwnPropertyDescriptor(a, "FS_createLink") || (a.FS_createLink = function() {
  t("'FS_createLink' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
Object.getOwnPropertyDescriptor(a, "FS_createDevice") || (a.FS_createDevice = function() {
  t("'FS_createDevice' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
Object.getOwnPropertyDescriptor(a, "FS_unlink") || (a.FS_unlink = function() {
  t("'FS_unlink' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ). Alternatively, forcing filesystem support (-s FORCE_FILESYSTEM=1) can export this for you");
});
Object.getOwnPropertyDescriptor(a, "dynamicAlloc") || (a.dynamicAlloc = function() {
  t("'dynamicAlloc' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "loadDynamicLibrary") || (a.loadDynamicLibrary = function() {
  t("'loadDynamicLibrary' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "loadWebAssemblyModule") || (a.loadWebAssemblyModule = function() {
  t("'loadWebAssemblyModule' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "getLEB") || (a.getLEB = function() {
  t("'getLEB' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "getFunctionTables") || (a.getFunctionTables = function() {
  t("'getFunctionTables' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "alignFunctionTables") || (a.alignFunctionTables = function() {
  t("'alignFunctionTables' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "registerFunctions") || (a.registerFunctions = function() {
  t("'registerFunctions' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
a.addFunction = function(c) {
  u("undefined" !== typeof c);
  for (var d = 0; 5 > d; d++) {
    if (!y[d]) {
      return y[d] = c, 1 + d;
    }
  }
  throw "Finished up all reserved function pointers. Use a higher value for RESERVED_FUNCTION_POINTERS.";
};
a.removeFunction = function(c) {
  y[c - 1] = null;
};
Object.getOwnPropertyDescriptor(a, "getFuncWrapper") || (a.getFuncWrapper = function() {
  t("'getFuncWrapper' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "prettyPrint") || (a.prettyPrint = function() {
  t("'prettyPrint' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "makeBigInt") || (a.makeBigInt = function() {
  t("'makeBigInt' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "dynCall") || (a.dynCall = function() {
  t("'dynCall' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "getCompilerSetting") || (a.getCompilerSetting = function() {
  t("'getCompilerSetting' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "print") || (a.print = function() {
  t("'print' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "printErr") || (a.printErr = function() {
  t("'printErr' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "getTempRet0") || (a.getTempRet0 = function() {
  t("'getTempRet0' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "setTempRet0") || (a.setTempRet0 = function() {
  t("'setTempRet0' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "callMain") || (a.callMain = function() {
  t("'callMain' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "abort") || (a.abort = function() {
  t("'abort' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stringToNewUTF8") || (a.stringToNewUTF8 = function() {
  t("'stringToNewUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "abortOnCannotGrowMemory") || (a.abortOnCannotGrowMemory = function() {
  t("'abortOnCannotGrowMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "emscripten_realloc_buffer") || (a.emscripten_realloc_buffer = function() {
  t("'emscripten_realloc_buffer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "ENV") || (a.ENV = function() {
  t("'ENV' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "ERRNO_CODES") || (a.ERRNO_CODES = function() {
  t("'ERRNO_CODES' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "ERRNO_MESSAGES") || (a.ERRNO_MESSAGES = function() {
  t("'ERRNO_MESSAGES' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "setErrNo") || (a.setErrNo = function() {
  t("'setErrNo' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "DNS") || (a.DNS = function() {
  t("'DNS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "GAI_ERRNO_MESSAGES") || (a.GAI_ERRNO_MESSAGES = function() {
  t("'GAI_ERRNO_MESSAGES' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "Protocols") || (a.Protocols = function() {
  t("'Protocols' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "Sockets") || (a.Sockets = function() {
  t("'Sockets' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "UNWIND_CACHE") || (a.UNWIND_CACHE = function() {
  t("'UNWIND_CACHE' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "readAsmConstArgs") || (a.readAsmConstArgs = function() {
  t("'readAsmConstArgs' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "jstoi_q") || (a.jstoi_q = function() {
  t("'jstoi_q' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "jstoi_s") || (a.jstoi_s = function() {
  t("'jstoi_s' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "reallyNegative") || (a.reallyNegative = function() {
  t("'reallyNegative' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "formatString") || (a.formatString = function() {
  t("'formatString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "PATH") || (a.PATH = function() {
  t("'PATH' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "PATH_FS") || (a.PATH_FS = function() {
  t("'PATH_FS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "SYSCALLS") || (a.SYSCALLS = function() {
  t("'SYSCALLS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "syscallMmap2") || (a.syscallMmap2 = function() {
  t("'syscallMmap2' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "syscallMunmap") || (a.syscallMunmap = function() {
  t("'syscallMunmap' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "flush_NO_FILESYSTEM") || (a.flush_NO_FILESYSTEM = function() {
  t("'flush_NO_FILESYSTEM' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "JSEvents") || (a.JSEvents = function() {
  t("'JSEvents' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "specialHTMLTargets") || (a.specialHTMLTargets = function() {
  t("'specialHTMLTargets' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "demangle") || (a.demangle = function() {
  t("'demangle' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "demangleAll") || (a.demangleAll = function() {
  t("'demangleAll' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "jsStackTrace") || (a.jsStackTrace = function() {
  t("'jsStackTrace' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stackTrace") || (a.stackTrace = function() {
  t("'stackTrace' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "getEnvStrings") || (a.getEnvStrings = function() {
  t("'getEnvStrings' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "writeI53ToI64") || (a.writeI53ToI64 = function() {
  t("'writeI53ToI64' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "writeI53ToI64Clamped") || (a.writeI53ToI64Clamped = function() {
  t("'writeI53ToI64Clamped' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "writeI53ToI64Signaling") || (a.writeI53ToI64Signaling = function() {
  t("'writeI53ToI64Signaling' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "writeI53ToU64Clamped") || (a.writeI53ToU64Clamped = function() {
  t("'writeI53ToU64Clamped' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "writeI53ToU64Signaling") || (a.writeI53ToU64Signaling = function() {
  t("'writeI53ToU64Signaling' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "readI53FromI64") || (a.readI53FromI64 = function() {
  t("'readI53FromI64' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "readI53FromU64") || (a.readI53FromU64 = function() {
  t("'readI53FromU64' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "convertI32PairToI53") || (a.convertI32PairToI53 = function() {
  t("'convertI32PairToI53' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "convertU32PairToI53") || (a.convertU32PairToI53 = function() {
  t("'convertU32PairToI53' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "Browser") || (a.Browser = function() {
  t("'Browser' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "FS") || (a.FS = function() {
  t("'FS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "MEMFS") || (a.MEMFS = function() {
  t("'MEMFS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "TTY") || (a.TTY = function() {
  t("'TTY' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "PIPEFS") || (a.PIPEFS = function() {
  t("'PIPEFS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "SOCKFS") || (a.SOCKFS = function() {
  t("'SOCKFS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "GL") || (a.GL = function() {
  t("'GL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "emscriptenWebGLGet") || (a.emscriptenWebGLGet = function() {
  t("'emscriptenWebGLGet' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "emscriptenWebGLGetTexPixelData") || (a.emscriptenWebGLGetTexPixelData = function() {
  t("'emscriptenWebGLGetTexPixelData' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "emscriptenWebGLGetUniform") || (a.emscriptenWebGLGetUniform = function() {
  t("'emscriptenWebGLGetUniform' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "emscriptenWebGLGetVertexAttrib") || (a.emscriptenWebGLGetVertexAttrib = function() {
  t("'emscriptenWebGLGetVertexAttrib' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "AL") || (a.AL = function() {
  t("'AL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "SDL_unicode") || (a.SDL_unicode = function() {
  t("'SDL_unicode' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "SDL_ttfContext") || (a.SDL_ttfContext = function() {
  t("'SDL_ttfContext' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "SDL_audio") || (a.SDL_audio = function() {
  t("'SDL_audio' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "SDL") || (a.SDL = function() {
  t("'SDL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "SDL_gfx") || (a.SDL_gfx = function() {
  t("'SDL_gfx' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "GLUT") || (a.GLUT = function() {
  t("'GLUT' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "EGL") || (a.EGL = function() {
  t("'EGL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "GLFW_Window") || (a.GLFW_Window = function() {
  t("'GLFW_Window' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "GLFW") || (a.GLFW = function() {
  t("'GLFW' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "GLEW") || (a.GLEW = function() {
  t("'GLEW' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "IDBStore") || (a.IDBStore = function() {
  t("'IDBStore' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "runAndAbortIfError") || (a.runAndAbortIfError = function() {
  t("'runAndAbortIfError' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "warnOnce") || (a.warnOnce = function() {
  t("'warnOnce' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stackSave") || (a.stackSave = function() {
  t("'stackSave' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stackRestore") || (a.stackRestore = function() {
  t("'stackRestore' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stackAlloc") || (a.stackAlloc = function() {
  t("'stackAlloc' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "AsciiToString") || (a.AsciiToString = function() {
  t("'AsciiToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stringToAscii") || (a.stringToAscii = function() {
  t("'stringToAscii' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "UTF16ToString") || (a.UTF16ToString = function() {
  t("'UTF16ToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stringToUTF16") || (a.stringToUTF16 = function() {
  t("'stringToUTF16' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "lengthBytesUTF16") || (a.lengthBytesUTF16 = function() {
  t("'lengthBytesUTF16' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "UTF32ToString") || (a.UTF32ToString = function() {
  t("'UTF32ToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "stringToUTF32") || (a.stringToUTF32 = function() {
  t("'stringToUTF32' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "lengthBytesUTF32") || (a.lengthBytesUTF32 = function() {
  t("'lengthBytesUTF32' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "allocateUTF8") || (a.allocateUTF8 = function() {
  t("'allocateUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
Object.getOwnPropertyDescriptor(a, "allocateUTF8OnStack") || (a.allocateUTF8OnStack = function() {
  t("'allocateUTF8OnStack' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
});
a.writeStackCookie = ia;
a.checkStackCookie = K;
a.abortStackOverflow = ja;
Object.getOwnPropertyDescriptor(a, "ALLOC_NORMAL") || Object.defineProperty(a, "ALLOC_NORMAL", {configurable:!0, get:function() {
  t("'ALLOC_NORMAL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
}});
Object.getOwnPropertyDescriptor(a, "ALLOC_STACK") || Object.defineProperty(a, "ALLOC_STACK", {configurable:!0, get:function() {
  t("'ALLOC_STACK' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
}});
Object.getOwnPropertyDescriptor(a, "ALLOC_DYNAMIC") || Object.defineProperty(a, "ALLOC_DYNAMIC", {configurable:!0, get:function() {
  t("'ALLOC_DYNAMIC' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
}});
Object.getOwnPropertyDescriptor(a, "ALLOC_NONE") || Object.defineProperty(a, "ALLOC_NONE", {configurable:!0, get:function() {
  t("'ALLOC_NONE' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)");
}});
var X;
a.then = function(c) {
  if (X) {
    c(a);
  } else {
    var d = a.onRuntimeInitialized;
    a.onRuntimeInitialized = function() {
      d && d();
      c(a);
    };
  }
  return a;
};
S = function Fa() {
  X || Ga();
  X || (S = Fa);
};
function Ga() {
  function c() {
    if (!X && (X = !0, a.calledRun = !0, !B)) {
      K();
      u(!N);
      N = !0;
      M(na);
      K();
      M(oa);
      if (a.onRuntimeInitialized) {
        a.onRuntimeInitialized();
      }
      u(!a._main, 'compiled without a main, but one is present. if you added it from JS, use Module["onRuntimeInitialized"]');
      K();
      if (a.postRun) {
        for ("function" == typeof a.postRun && (a.postRun = [a.postRun]); a.postRun.length;) {
          var d = a.postRun.shift();
          pa.unshift(d);
        }
      }
      M(pa);
    }
  }
  if (!(0 < O)) {
    ia();
    if (a.preRun) {
      for ("function" == typeof a.preRun && (a.preRun = [a.preRun]); a.preRun.length;) {
        qa();
      }
    }
    M(ma);
    0 < O || (a.setStatus ? (a.setStatus("Running..."), setTimeout(function() {
      setTimeout(function() {
        a.setStatus("");
      }, 1);
      c();
    }, 1)) : c(), K());
  }
}
a.run = Ga;
if (a.preInit) {
  for ("function" == typeof a.preInit && (a.preInit = [a.preInit]); 0 < a.preInit.length;) {
    a.preInit.pop()();
  }
}
noExitRuntime = !0;
Ga();



  return clove
}
);
})();
export default clove;