import { Clove, loadWasm } from './clove'

loadWasm()

const startStop = document.querySelector('#start-stop')
const transcript = document.querySelector('#transcript')

let audioBuffer
let audioContext
let audioProcessor
let recording
let stream
let sampleRate

document.querySelector('#activate').addEventListener('click', activateMic)

startStop.addEventListener('click', e => {
  recording ? stopRecording(e) : startRecording(e)
})

function activateMic (e) {
  e.preventDefault()
  window.navigator.mediaDevices.getUserMedia({ audio: true })
    .then(mediaStream => {
      const AudioContext = window.AudioContext || window.webkitAudioContext
      console.log(mediaStream)
      e.target.disabled = true
      startStop.disabled = false
      stream = mediaStream
      audioContext = new AudioContext()
      audioProcessor = audioContext.createScriptProcessor(2048, 1, 1)
      audioProcessor.connect(audioContext.destination)
      sampleRate = audioContext.sampleRate
      prepareAudioContext(stream.clone())
      e.target.classList.add('hidden')
      startStop.classList.remove('hidden')
      startStop.focus()
      window.location.ancestorOrigins && window.location.ancestorOrigins.length === 1
        ? window.top.postMessage('activate', window.location.ancestorOrigins[0])
        : console.error('postMessage interaction requires a single ancestorOrigin')
      // launchIntoFullscreen(document.documentElement)
    }).catch(() => {
      window.location.reload()
    })
}

function bufferAudio (audio) {
  if (audioBuffer) {
    let buffer = new Float32Array(audioBuffer.length + audio.length)
    buffer.set(audioBuffer)
    buffer.set(audio, audioBuffer.length)
    audioBuffer = buffer
  }
}

function encodeBuffer (buffer) {
  const clove = new Clove(sampleRate)
  const output = clove.process(buffer)
  return !!output ? output[0] : undefined
}

// function launchIntoFullscreen (element) {
//   if (element.requestFullscreen) {
//     console.log('element.requestFullscreen')
//     element.requestFullscreen()
//   } else if(element.mozRequestFullScreen) {
//     console.log('element.mozRequestFullScreen')
//     element.mozRequestFullScreen()
//   } else if(element.webkitRequestFullscreen) {
//     console.log('element.webkitRequestFullscreen')
//     element.webkitRequestFullscreen()
//   } else if(element.msRequestFullscreen) {
//     console.log('element.msRequestFullscreen')
//     element.msRequestFullscreen()
//   }
// }

function prepareAudioContext (newStream) {
  const audioSource = audioContext.createMediaStreamSource(newStream)
  audioSource.connect(audioProcessor)
}

function startRecording (e) {
  e.preventDefault()
  console.time('Microphone latency')
  e.target.innerText = 'Stop recording'
  if (audioContext.state === 'suspended') audioContext.resume()
  audioBuffer = new Float32Array(0)
  recording = true
  console.timeEnd('Microphone latency')
  audioProcessor.onaudioprocess = e => {
    bufferAudio(e.inputBuffer.getChannelData(0))
  }
}

function stopRecording (e) {
  e.preventDefault()
  recording = false
  e.target.innerText = 'Start recording'
  const audio = encodeBuffer(audioBuffer)
  if (audio) {
    submitAudio(audio)
  } else {
    console.log('No audio was captured')
    // perhaps ask the user to go again
  }
  audioContext.suspend().then(() => {
    audioBuffer = undefined
  })
  prepareAudioContext(stream.clone())
}

function submitAudio (audio) {
  console.time('Roundtrip')
  fetch('https://moodle.scruffy.workers.dev', {
    body: JSON.stringify({
      key: 'the owls are not what they seem.',
      type: 'audio',
      payload: audio
    }),
    headers: { "Content-Type": "application/json; charset=utf-8" },
    method: "POST"
  })
  .then(response => {
    if (response.ok) return response.json()
    return Promise.reject(response)
  })
  .then(data => {
    transcript.innerText = data.payload
    console.log(data)
    console.timeEnd('Roundtrip')
  })
  .catch(error => {
    transcript.innerText = error
    console.warn(error)
    console.timeEnd('Roundtrip')
  })
}
